import React, {useState, useEffect} from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import breakpoints from "src/styles/breakpoints"



const Image = ({ image, topBorderRadius, bottomBorderRadius }) => {



  if (!topBorderRadius) {
    return (
      <GatsbyImage
        image={image && image.gatsbyImageData}
        alt={image.description}
        fadein={true}
      />
    )
  } else {
    let styleObj = {
      zIndex: "0"
    }
    if (topBorderRadius ){
      styleObj.borderTopLeftRadius = topBorderRadius;
      styleObj.borderTopRightRadius = topBorderRadius;
    }
    if (bottomBorderRadius) {
      styleObj.borderBottomRightRadius = bottomBorderRadius;
      styleObj.borderBottomLeftRadius = bottomBorderRadius;
    }

 

    return (
      <GatsbyImage
        style={styleObj}
        image={image && image.gatsbyImageData}
        alt={image.description}
        fadein={true}
      />
    )
  }
}

export default Image
