import React from "react"
import styled from "styled-components"
import Text from "./Text"
import Image from "./Image"
import breakpoints from "../../styles/breakpoints"

const BlockContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 75%;
    display: flex;
    margin: auto;
  }

  padding-top: 17px;
`

const LogoContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 25%;
    margin-right: 9%;
  }

  margin-bottom: 2rem;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`

const TextContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    width: 66%;

    p {
      margin-bottom: 20px;
      :last-of-type {
        margin-bottom: 0;

      }
    }
  }

  h4 {
    margin-bottom: 1rem;
    font-size: calc(0.12vw + 1rem);
  }
`

const PressPageBlock = (props) => {
  const {pressLogo, text} = props.block

  return (
    <BlockContainer>
      <LogoContainer>
        <Image image={pressLogo} />
      </LogoContainer>
      <TextContainer>
        <Text content={text} />
      </TextContainer>
    </BlockContainer>
  )
}

export default PressPageBlock
