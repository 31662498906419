import React, { Fragment } from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import colors from "../styles/colors"
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../components/Layout/Layout"
import breakpoints from "../styles/breakpoints"
import PressPageBlock from "../components/Blocks/PressPageBlock"

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`

const PageContent = styled.div`
  max-width: ${breakpoints.xxl};
  margin: auto;
`

const Heading2 = styled.h2`
  text-align: center;
  color: ${colors.royalBlue};
  margin-bottom: 68px;
`

const Grid = styled.div`
  display: grid;
  gap: 60px;
`

const TestPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  let sharingImage = false
  
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <PageContent>
          <Heading2>{page.title}</Heading2>
          <Grid>
            {page.blocks.map((block, index) => {
              const { __typename: type } = block
              if (type === "ContentfulPressPageBlock") {
                return (
                  <PressPageBlock block={block} key={index}></PressPageBlock>
                )
              }
            })}
          </Grid>
        </PageContent>
      </PageContainer>
    </Layout>
  )
}

export default TestPage

export const pageQuery = graphql`
  query TestQuery {
    contentfulPage(slug: { eq: "press" }) {
      ...pageFragment
    }
  }
`
